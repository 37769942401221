import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Handle } from 'reactflow'

import { Box, Badge, Spinner, Flex, Text, Icon } from '@chakra-ui/react'

import { LinkIcon } from '@heroicons/react/20/solid'

import {store} from '../../utils/client/store'

import {nFormatter, getHostname, isValidUrl, formatUrl} from '../../utils/client'

import {showBackground, background} from '../../data/steps'

import {getFromLocalImage} from '../../utils/client/sites'

import {nodeStyles} from '../../utils/client/nodes'

import axios from 'axios'

const UrlElement = ({url, selected}) => {

	const [imageUrl, setImageUrl] = useState()
	const [isLoading, setIsLoading] = useState(false)

	const getImage = useCallback(async () => {

		// check valid url
		if (!isValidUrl(url)) {
			setImageUrl(null)
			return
		}

		// get local image
		const imageUrlLocal = getFromLocalImage(url)
		if (imageUrlLocal) {
			setImageUrl(imageUrlLocal)
			return
		}

		// get remote image
		setIsLoading(true)
		const {data} = await axios.get(`/api/helper/image?url=${url}`)
		setImageUrl(data.imageUrl)
		setIsLoading(false)
	}, [url])

	useEffect(() => { getImage() }, [getImage])

	return (
		<Box draggable="false" width="100%">
			<Box borderWidth="1px" borderRadius="lg" width="100%" height="120px" backgroundColor="white" style={{ boxShadow: selected ? 'rgba(100, 100, 111, 0.2) 0px 0px 12px 0px' : '' }} padding="20px" justifyContent="center" display="flex" alignItems="center">
			{isLoading ? 
				<Spinner/> :
				imageUrl ? 
					<img src={imageUrl}  alt='screen' draggable="false"  style={{ objectFit: 'contain', borderRadius: '6px', height: '100%' }} width="100%"/> :
					<Flex flexDirection="column" alignItems="center" marginTop="10px">
						<Icon as={LinkIcon} fontSize="20px" marginBottom="10px"/>
						<Text fontSize="x-small" maxWidth="68px">{getHostname(url)}</Text>
					</Flex>}				
			</Box>
		</Box>
	)
}

const DefaultElement = ({selected, imageUrl}) => {
	return (
		<Box>
			{showBackground && <img src={background} alt='screen background' draggable="false" style={{ position: 'absolute', zIndex: -2 }} onDragStart={(e) => e.preventDefault()}/>}
			<img src={imageUrl} alt='screen' style={{ boxShadow: selected ? 'rgba(100, 100, 111, 0.2) 0px 0px 12px 0px' : '' }} draggable='false' onDragStart={(e) => e.preventDefault()}/>
		</Box>
	)
}

const CanvasElement = ({ data, selected }) => {
	const {state} = useContext(store)

	return (
		<div style={{ ...nodeStyles, display: 'flex', justifyContent: 'center' }} >
			<Handle type="source" position="right" padding="40px"/>
			{data.type2 !== 'url' ? <DefaultElement selected={selected} imageUrl={data.imageUrl}/> : <UrlElement selected={selected} url={formatUrl(data.url)} />}
			{(data.type === 'acquisition' && data.leads && (state.showHits || data.cac !== '')) ? <Badge position="absolute" bottom="-28px" colorScheme={state.showHits ? "purple" : "red"}>{state.showHits ? `${nFormatter(data.leads)} leads` : `$${nFormatter(data.leads * data.cac)}`}</Badge> : null}
			{(data.type === 'conversion' && data.hits && (state.showHits || data.ltv !== '')) ? <Badge position="absolute" bottom="-28px" colorScheme={state.showHits ? "blackAlpha" : "green"}>{state.showHits ? `${nFormatter(data.hits)} hits` : `$${nFormatter(data.hits * data.ltv)}`}</Badge> : null}
			{/* HACKY - fixed dynamic handles (1) */}
			<Handle type="target" position="left" style={{opacity: data.type === 'acquisition' ? 0 : 1, pointerEvents: data.type === 'acquisition' ? 'none' : 'all'}} />
		</div>
	)
}
export default CanvasElement