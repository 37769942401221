import { useContext } from 'react'

import { Box, Heading, Badge } from '@chakra-ui/react'

import {calculateTotalCac, calculateTotalLtv} from '../../utils/client/nodes'
import {store} from '../../utils/client/store'
import {nFormatter} from '../../utils/client'

const ProfitPanel = () => {
    const {state} = useContext(store)

	const totalCac = calculateTotalCac(state.funnelNodes)
	const totalLtv = calculateTotalLtv(state.funnelNodes)
	const totalProfit = totalLtv - totalCac
    return (
        !state.showHits && 
            <Box position="absolute" top="76px" right="20px" borderWidth="1px" borderRadius="lg" padding="8px 14px">
                <Heading size="sm">
                    {'Net: '}
                    <Badge fontSize="18px" marginBottom="2px" colorScheme={totalProfit >= 0 ? (totalProfit === 0 ? "blackAlpha" : "green") : "red"}>
                        ${nFormatter(Math.abs(Math.round(totalProfit)))}
                    </Badge>
                </Heading>
            </Box>
    )
}
export default ProfitPanel