const si = [
	{ value: 1, symbol: '' },
	{ value: 1E3, symbol: 'k' },
	{ value: 1E6, symbol: 'M' },
	{ value: 1E9, symbol: 'G' },
	{ value: 1E12, symbol: 'T' },
	{ value: 1E15, symbol: 'P' },
	{ value: 1E18, symbol: 'E' }
]

const nFormatter = (num) => {
	let i
	for (i = si.length - 1; i > 0; i--) {
		if (num >= si[i].value) break
	}
	const num1 = Math.round(num) / si[i].value
	const digits = num1 > 0 ? Math.floor(Math.log10(num1)) + 1 : 0
	const num2 = (num1).toFixed(3 - digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
	return num2.toString()
}
export {nFormatter}

const isEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase())
}
export {isEmail}

const getHostname = (url) => {
	try {
		const d = new URL(url)
		if (d.hostname.startsWith('www.')) {
			return d.hostname.substring(4, d.hostname.length)
		} else {
			return d.hostname
		}
	} catch (error) {
		return 'Invalid Link'
	}
}
export {getHostname}

const isValidUrl = (url) => {
	const pattern = new RegExp('^((http|https):)\/\/(.+)\.(.+)$')
  return pattern.test(url);
}
export {isValidUrl}

function debounce (fn, wait) {
	let t
	return function () {
		clearTimeout(t)
		t = setTimeout(() => fn.apply(this, arguments), wait)
	}
}
export {debounce}

const transform = (c) => {
	const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
	return v.toString(16)
}
const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => transform(c))
export {uuidv4}

const formatUrl = (s) => !s.startsWith('http') ? 'https://' + s : s

export {formatUrl}