import { useContext, useEffect, useState } from 'react'

import { useToast, useDisclosure } from "@chakra-ui/react"

import axios from 'axios'

import { useRouter } from 'next/router'

import {debounce} from '../utils/client'
import {store} from '../utils/client/store'
import {toastOptions} from '../utils/client/toast'

import GetStartedModal from '../components/modals/GetStarted'
import SettingsPanel from '../components/panels/SettingsPanel'
import CanvasContainer from '../components/canvas/CanvasContainer'
import TopBar from '../components/topbar/TopBar'
// import MidPanel from '../components/panels/MidPanel'

import dynamic from 'next/dynamic'

import SocialHead from '../components/head/SocialHead'

import 'reactflow/dist/style.css'
import 'reactflow/dist/base.css'

const Layout = dynamic(() => import('../components/shared/Layout'), {ssr: false})

const Index = () => {
	
	const {state, dispatch} = useContext(store)
	
	const toast = useToast()
	const router = useRouter()

	const selectMenuDisclosure = useDisclosure()
	const accountMenuDisclosure = useDisclosure()
	const mobileMenuDisclosure = useDisclosure()
	const getStartedMenuDisclosure = useDisclosure()

	const disclosures = {selectMenuDisclosure, accountMenuDisclosure, mobileMenuDisclosure}
	
	const saveFunnel = async (funnelId, elements) => {
		if (funnelId) {
			dispatch({ type: 'SET_IS_SAVING', value: true })
			await axios.put(`/api/funnels/${funnelId}`, { elements: elements }, { headers: { authorization: state.token } })
			dispatch({ type: 'SET_IS_SAVING', value: false })
		} else {
			const { data } = await axios.post('/api/funnels/save', { elements: instance }, { headers: { authorization: state.token } })
			router.push(`/funnels/${data.funnelId}`, null, { shallow: true })
		}
	}

	useEffect(() => {
		const status = state.user?.subscriptionStatus
		if (status === 'active' && state.aboutToSave) {
			const { funnelId } = router.query
			const elements = state.funnelNodes.concat(state.funnelEdges)
			debounce(() => {
				dispatch({ type: 'SET_ABOUT_TO_SAVE', value: false })
				saveFunnel(funnelId, elements)
			}, 2000)()
		}
	}, [state.aboutToSave])

	const getUserWithFunnels = async () => {
		// get user funnels
		const {data} = await axios.get(`/api/auth/user`, { headers: { 'Content-Type': 'application/json', authorization: state.token } })
		dispatch({ type: 'SET_USER', value: data.user })
		dispatch({ type: 'SET_FUNNELS', value: data.funnels })

		// set funnel
		const index = (state.funnelEdges?.length > 0  || state.funnelNodes?.length > 0) ? data.funnels.length - 1 : 0
		const funnelNodes = data.funnels[index].elements.filter(n => n.type === 'element')
		const funnelEdges = data.funnels[index].elements.filter(n => n.type === 'link')
		dispatch({ type: 'SET_FUNNEL_INDEX', value: index})
		dispatch({ type: 'SET_FUNNEL_NODES', value: funnelNodes})
		dispatch({ type: 'SET_FUNNEL_EDGES', value: funnelEdges})
		router.push(`/funnels/${data.funnels[index].id}`,null, { shallow: true })
	}

	const getSession = async (sessionId) => {
		const {data} = await axios.get(`/api/auth/session?session_id=${sessionId}`, { headers: { 'Content-Type': 'application/json' } })
		dispatch({ type: 'SET_USER_TOKEN', value: data.token })
		toast({ title: "You changed plan", variant: 'subtle', description: "You have subscribed to the \"Premium Plan\"!", status: "success", ...toastOptions })
		router.push('/', null, { shallow: true })
	}
	
	const getFunnel = async (funnelId) => {
		dispatch({ type: 'SET_LOADING', value: true })
		const {data} = await axios.get(`/api/funnels/${funnelId}`, { headers: { 'Content-Type': 'application/json', authorization: state.token } })
		const funnelNodes =  data.elements.filter(n => n.type === 'element')
		const funnelEdges =  data.elements.filter(n => n.type === 'link')
		dispatch({ type: 'SET_FUNNEL_NODES', value: funnelNodes})
		dispatch({ type: 'SET_FUNNEL_EDGES', value: funnelEdges})
		dispatch({ type: 'SET_LOADING', value: false })
		setTimeout(() => state.instance?.fitView({maxZoom: 1}), 50)
	}

	useEffect(() => {
		const sessionId = router.query.session_id
		if (sessionId && sessionId !== 'null') {
			getSession(sessionId)
		}
	}, [router.query.session_id])

	useEffect(() => {
		// get funnel
		const funnelId = router.query.funnelId
		if (funnelId && state.token) {
			getFunnel(funnelId)
		} else if (!state.funnelEdges || !state.funnelNodes) {
			dispatch({ type: 'SET_FUNNEL_NODES', value: []})
			dispatch({ type: 'SET_FUNNEL_EDGES', value: []})
		}

		// redirect
		if (funnelId && !state.token) router.push(`/`)
	}, [router.query.funnelId])

	useEffect(() => {
		if (state.token) {
			getUserWithFunnels()
		}
	}, [state.token])

	useEffect(() => {
		const isFirstTime = localStorage.getItem('first_time') !== 'false'
		if (isFirstTime) getStartedMenuDisclosure.onOpen()
	}, [])

	return (
		<>
			<SocialHead/>
			<Layout>
				<GetStartedModal isOpen={getStartedMenuDisclosure.isOpen} onClose={() => {localStorage.setItem('first_time', false); getStartedMenuDisclosure.onClose()}}/>
				<TopBar {...disclosures}/>
				<CanvasContainer {...disclosures} />
				{(state.selectedElements?.nodes.length > 0 || state.selectedElements?.edges.length > 0) && <SettingsPanel/>}
				{/* {(state.selectedElements.nodes.length > 0 || state.selectedElements.edges.length > 0) && <MidPanel/>} */}
			</Layout>
		</>
	)
}
export default Index
