import { useContext, useEffect } from 'react'

import { Box, Button, Icon, forwardRef, IconButton, useToast, useDisclosure } from '@chakra-ui/react'
import { Menu, MenuButton, MenuList, MenuItem, MenuDivider } from "@chakra-ui/react"

import { TrashIcon, PencilSquareIcon } from '@heroicons/react/20/solid'

import { UserIcon, Bars3Icon, LinkIcon, ArrowTopRightOnSquareIcon, PlusIcon, CheckCircleIcon, ArrowsRightLeftIcon, ChevronDownIcon } from '@heroicons/react/24/solid'

import DeleteFunnel from '../modals/DeleteFunnel'
import RenameFunnel from '../modals/RenameFunnel'
import OurPromiseModal from '../modals/OurPromise'
import SignUpModal from '../modals/SignUp'

import { useMediaQuery } from '@chakra-ui/react'

import { useRouter } from 'next/router'

import axios from 'axios'

import { toastOptions, useToastAsync } from '../../utils/client/toast'
import { store } from '../../utils/client/store'

import { loadStripe } from "@stripe/stripe-js"

import LogoutIcon from '../icons/LogoutIcon'
import SaveIcon from '../icons/SaveIcon'
import LoginIcon from '../icons/LoginIcon'

import { useGoogleLogin } from '@react-oauth/google'

const portalToastOptions = { title: 'Loading Billing' }
const checkoutToastOptions = { title: 'Loading Checkout' }

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY)

const defaultName = 'Untitled'

const ButtonMaxWidth = forwardRef(({ children, ...props }, ref) => (
	<Button ref={ref} {...props}>
		<span style={{ textOverflow: 'ellipsis', maxWidth: '160px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
			{children}
		</span>
	</Button>
))

const TopBar = ({ selectMenuDisclosure, accountMenuDisclosure, mobileMenuDisclosure }) => {
	
	const toast = useToast()
	const router = useRouter()
	
	const { state, dispatch } = useContext(store)
	
	const [isTablet] = useMediaQuery('(max-width: 1140px)')

	const { isOpen: isOpenSignupModal, onOpen: onOpenSignupModal, onClose: onCloseSignupModal } = useDisclosure()
	const { isOpen: isOpenPromiseModal, onOpen: onOpenPromiseModal, onClose: onClosePromiseModal } = useDisclosure()
	const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure()
	const { isOpen: isOpenRenameModal, onOpen: onOpenRenameModal, onClose: onCloseRenameModal } = useDisclosure()

	
	const setShowPortalToast = useToastAsync(false, portalToastOptions)
	const setShowCheckoutToast = useToastAsync(false, checkoutToastOptions)

	const status = state.user?.subscriptionStatus

	const login = useGoogleLogin({ flow: 'auth-code', onSuccess: res => loginSuccess(res, false) })

	useEffect(() => {
		if (!state.isLoaded) {
			dispatch({ type: 'SET_LOADED', value: true })
		}
	}, [])

	const newFunnel = async () => {
		let elements = []
		if (state.aboutToSave) {
			const instance = state.instance.toObject()
			elements = instance.elements
		}
		const { data } = await axios.post(`/api/funnels/add`, { elements }, { headers: { 'Content-Type': 'application/json', authorization: state.token } })
		const funnels = [...state.funnels, { id: data.funnelId }]
		dispatch({ type: 'SET_FUNNELS', value: funnels })
		router.push(`/funnels/${data.funnelId}`, null, { shallow: true })

		const i = funnels.findIndex(f => f.id == data.funnelId)
		dispatch({ type: 'SET_FUNNEL_INDEX', value: i })

		dispatch({ type: 'SET_FUNNEL_NODES', value: [] })
		dispatch({ type: 'SET_FUNNEL_EDGES', value: [] })
		dispatch({ type: 'SET_ABOUT_TO_SAVE', value: false })
	}

	const loginSuccess = async (res, save = false) => {
		try {
			const instance = state.instance.toObject()
			const elements = instance.nodes.concat(instance.edges)

			const { data } = await axios.post('/api/auth/continue', { code: res.code, ...(save && { elements: elements }) })

			if (data.sessionId) {
				const stripe = await stripePromise
				setShowCheckoutToast(true)
				await stripe.redirectToCheckout({ sessionId: data.sessionId })
				setShowCheckoutToast(false)
			} else {
				dispatch({ type: 'SET_USER_TOKEN', value: data.token })
			}

		} catch (error) {
			toast({ title: "Error", variant: 'subtle', description: "Please try again later :(", status: "error", ...toastOptions })
			console.log(error)
		}
	}

	const loginError = async (e) => {
		if (e.error === 'idpiframe_initialization_failed') return
		
		toast({ title: "Error", variant: 'subtle', description: "Please try again later :(", status: "error", ...toastOptions })
	}

	const showPortal = async () => {
		if (status === 'active') {
			setShowPortalToast(true)
			const { data } = await axios.get(`/api/auth/portal`, { headers: { authorization: state.token } })
			window.location.href = data.url
			setShowPortalToast(false)
		} else {
			setShowCheckoutToast(true)
			const stripe = await stripePromise
			const { data } = await axios.get('/api/auth/checkout', { headers: { authorization: state.token } })
			await stripe.redirectToCheckout({ sessionId: data.sessionId })
			setShowCheckoutToast(false)
		}
	}

	const saveFunnel = async () => {
		onOpenSignupModal()
	}

	const toggleView = () => {
		dispatch({ type: 'SET_SHOW_HITS', value: !state.showHits })
	}

	const logout = () => {
		dispatch({ type: 'SET_LOGOUT_USER' })
	}

	const selectFunnel = (funnel) => {
		const i = state.funnels.findIndex((q) => q === funnel)
		dispatch({ type: 'SET_FUNNEL_INDEX', value: i })
		router.push(`/funnels/${state.funnels[i].id}`)
	}

	return (
		<Box position="relative" zIndex="20">
			{state.funnels && state.token && <Box position="absolute" top="20px" left="0" right="0" textAlign="center">
				<>
					<Menu isOpen={selectMenuDisclosure.isOpen} onOpen={selectMenuDisclosure.onOpen} onClose={selectMenuDisclosure.onClose}>
						<MenuButton as={ButtonMaxWidth} rightIcon={<Icon as={ChevronDownIcon} />} marginRight="16px">
							{state.funnels[state.funnelIndex]?.title ?? defaultName}
						</MenuButton>
						<MenuList>
							{state.funnels.map((funnel, i) => <MenuItem key={i} onClick={() => selectFunnel(funnel)}>{funnel?.title ?? 'Untitled'}</MenuItem>)}
							{state.funnels.length > 1 && <MenuDivider />}
							<MenuItem icon={<Icon as={PlusIcon} />} onClick={() => newFunnel()}>New Funnel</MenuItem>
						</MenuList>
					</Menu>
					<IconButton icon={<Icon as={PencilSquareIcon} />} variant="ghost" onClick={onOpenRenameModal} />
					<RenameFunnel isOpen={isOpenRenameModal} onClose={onCloseRenameModal} />
					{state.funnels?.length > 1 && (
						<>
							<IconButton icon={<Icon as={TrashIcon} />} variant="ghost" onClick={onOpenDeleteModal}/>
							<DeleteFunnel isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}/>
						</>
					)}
				</>
			</Box>}
			<Box position="absolute" top="20px" right="20px">
				{!isTablet ?
					!state.token ? 
						<>
							<Button marginRight="16px" isDisabled onClick={() => {}}>Share (soon)</Button>
							<Button marginRight="16px" onClick={toggleView}>{!state.showHits ? 'Hits' : '$'}</Button>
							<Button isDisabled={state.funnelNodes?.length === 0 && state.funnelEdges?.length === 0} onClick={onOpenSignupModal} marginRight="16px">Save</Button>
							<Button marginRight="16px" onClick={login}>Login</Button>
							<Button onClick={onOpenSignupModal} marginRight="16px">Sign up</Button>
							<Button onClick={onOpenPromiseModal} colorScheme="purple">Our Promise</Button>
						</> : 
						<>
							<Button marginRight="16px" isDisabled onClick={() => {}}>Share (soon)</Button>
							<Button marginRight="16px" onClick={toggleView}>{!state.showHits ? 'Hits' : '$'}</Button>
							{(status !== 'active' && state.funnels?.length > 0 && <Button onClick={saveFunnel} marginRight="16px" colorScheme="purple">Save</Button>)}

							<Menu isOpen={accountMenuDisclosure.isOpen} onOpen={accountMenuDisclosure.onOpen} onClose={accountMenuDisclosure.onClose}>
								<MenuButton as={Button} rightIcon={<Icon as={ChevronDownIcon} />}>
									Account
								</MenuButton>
								<MenuList>
									<MenuItem icon={<Icon as={ArrowTopRightOnSquareIcon} />} onClick={showPortal}>{status === 'active' ? 'Billing' : 'Subscribe'}</MenuItem>
									<MenuDivider />
									<MenuItem icon={<LogoutIcon />} onClick={logout}>Logout</MenuItem>
								</MenuList>
							</Menu>
						</> :
						<>
					<Menu isOpen={mobileMenuDisclosure.isOpen} onOpen={mobileMenuDisclosure.onOpen} onClose={mobileMenuDisclosure.onClose}>
						<MenuButton as={IconButton} icon={<Icon as={Bars3Icon} />} variant="outline"/>
						<MenuList>
							{state.token ?
								<>
									<MenuItem icon={<Icon as={LinkIcon} />} isDisabled>Share (soon)</MenuItem>
									{state.aboutToSave && <MenuItem icon={<SaveIcon />} onClick={saveFunnel}>Save</MenuItem>}
									<MenuItem icon={<Icon as={ArrowsRightLeftIcon} />} onClick={toggleView}>Show {!state.showHits ? 'Hits' : '$'}</MenuItem>
									<MenuItem icon={<Icon as={ArrowTopRightOnSquareIcon} />} onClick={showPortal}>{status === 'active' ? 'Billing' : 'Subscribe'}</MenuItem>
									<MenuItem icon={<LogoutIcon />} onClick={logout}>Logout</MenuItem>
								</> :
								<>
									<MenuItem icon={<Icon as={LinkIcon} />} isDisabled>Share (soon)</MenuItem>
									<MenuItem icon={<SaveIcon />} onClick={saveFunnel}>Save</MenuItem>
									<MenuItem icon={<Icon as={LoginIcon} />} onClick={() => { login(); onCloseSignupModal() }}>Login</MenuItem>
									<MenuItem icon={<Icon as={UserIcon} />} onClick={onOpenSignupModal}>Signup</MenuItem>
									<MenuItem icon={<Icon as={ArrowsRightLeftIcon} />} onClick={toggleView}>Show {!state.showHits ? 'Hits' : '$'}</MenuItem>
									<MenuItem icon={<Icon as={CheckCircleIcon} />} onClick={onOpenPromiseModal}>Promise</MenuItem>
								</>}
						</MenuList>
					</Menu>
					</>}
			</Box>
			<SignUpModal isOpen={isOpenSignupModal} onClose={onCloseSignupModal} showPortal={showPortal} loginSuccess={loginSuccess} loginError={loginError} />
			<OurPromiseModal isOpen={isOpenPromiseModal} onClose={onClosePromiseModal}/>
		</Box>
	)
}

export default TopBar
