import { useContext } from 'react'

import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, useToast } from "@chakra-ui/react"

import { store } from '../../utils/client/store'

import { useGoogleLogin } from '@react-oauth/google'

const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID

const SignupModal = ({ isOpen, onClose, showPortal, loginSuccess, loginError }) => {
	const { state } = useContext(store)

	const status = state.user?.subscriptionStatus

	const login = useGoogleLogin({ flow: 'auth-code', onSuccess: res => loginSuccess(res, true) })

	return (

		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Join Pretty Funnels</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Are you looking to save your work? <br />
					<br />
						We just <b>launched subscriptions</b> and we are having a discount 🎉 for our first 550 users!<br />
					<br />
						First <b>50</b> users: <br />
						→ €5/mo 🥇 <s>(€25/mo)</s><br />
					<br />
						The next <b>500</b> users:<br />
						→ €10/mo 🥈 <s>(€25/mo)</s>

					{/* <FormControl>
							<Input type="email" mt="5" placeholder="Email eg. seth@godin.com" onChange={(e) => setEmail(e.target.value)} required />
							<FormHelperText>We'll never share your email.</FormHelperText>
						</FormControl> */}
				</ModalBody>

				<ModalFooter>
					<Button variant="ghost" mr={3} onClick={onClose}>Close</Button>
					{state.token && status !== 'active' ?
						<Button onClick={() => { showPortal(); onClose() }} marginRight="16px" colorScheme="purple">Subscribe ($5/mo)</Button> :
						<Button marginRight="16px" colorScheme="purple" onClick={() => { login(); onClose()}}>Subscribe ($5/mo)</Button>}
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
export default SignupModal