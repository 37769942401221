import { useState, useContext } from 'react'

import { store } from '../../utils/client/store'

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react"
import { Box, Text, Textarea, InputGroup, InputLeftAddon, Input, Stack, Radio, RadioGroup , Button, Link} from "@chakra-ui/react"

import axios from 'axios'

const surveyQuestions = {
	q1: '😊 How your experience with Pretty Funnels?',
	q2: '👨‍💻 Which features are you using the most?',
	q3: '🗣 Would you share the product with friends or collegues?'
}

const ThoughtsModal = ({ isOpen, onClose }) => {
	const {state} = useContext(store)
	const [text, setText] = useState('')
	const [email, setEmail] = useState(null)
	const [showSurvey, setShowSurvey] = useState(false)
	const [survey, setSurvey] = useState({})
	const submitThoughts = async () => {
		await axios.post(`/api/feedback/thoughts`, {email, text}, { headers: { 'Content-Type': 'application/json', authorization: state.token } })
		setText('')
	}
	const submitSurvey = async () => {
		const text = Object.keys(survey).map(q => `${surveyQuestions[q]} ${survey[q]}`).join('\n')
		await axios.post(`/api/feedback/survey`, {text}, { headers: { 'Content-Type': 'application/json', authorization: state.token } })
		setSurvey({})
	}
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent marginTop={'0px'} marginBottom="60px" maxW="520px">
				<ModalHeader>{!showSurvey ? 'Share a thought' : 'Share your experience'}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{!showSurvey ? <Box>
						<Text marginBottom="2">We love thoughts from our users, especial ideas to improve <b>Pretty Funnels</b>!</Text>
						<Textarea marginBottom="2" placeholder="Share an idea or a thought with the team..." onChange={(e) => setText(e.target.value)}/>
						{!state.token && <>
							<Text marginBottom="2">Enter an email so we can <u>reach back</u> to you.</Text>
							<InputGroup marginBottom="2">
								<InputLeftAddon children="Email" />
								<Input type="email" placeholder="seth@godin.com (Optional)" onChange={(e) => setEmail(e.target.value)}/>
							</InputGroup>
						</>}
						<Text marginTop="6">👉 We also have <Link color="var(--chakra-colors-purple-500)" fontWeight="bold" onClick={() => setShowSurvey(true)}>a short survey</Link> to keep an eye on our users experience.</Text>
					</Box> :
					<Box>
						<Text marginBottom="2">{surveyQuestions.q1}</Text>
						<RadioGroup marginBottom="6" onChange={(e) => setSurvey(s => ({...s, q1: e}))} value={survey.q1}>
							<Stack direction="row">
								<Radio value="not_ideal">Not ideal</Radio>
								<Radio value="good">Good</Radio>
								<Radio value="great">Great</Radio>
								<Radio value="excellent">Excellent</Radio>
							</Stack>
						</RadioGroup>
						
						<Text marginBottom="2">{surveyQuestions.q2}</Text>
						<RadioGroup marginBottom="6" onChange={(e) => setSurvey(s => ({...s, q2: e}))} value={survey.q2}>
							<Stack direction="row">
								<Radio value="planning">Marketing planning</Radio>
								<Radio value="calculations">Profit calculations</Radio>
								<Radio value="sharing">To share funnels</Radio>
							</Stack>
						</RadioGroup>
						
						<Text marginBottom="2">{surveyQuestions.q3}</Text>
						<RadioGroup marginBottom="2" onChange={(e) => setSurvey(s => ({...s, q3: e}))} value={survey.q3}>
							<Stack direction="row">
								<Radio value="not_really">Not really</Radio>
								<Radio value="maybe">Maybe</Radio>
								<Radio value="if_relevant">If relevant</Radio>
								<Radio value="absolutely">Absolutely</Radio>
							</Stack>
						</RadioGroup>
						{/* <Text marginBottom="2">📝 Something else you want to share...</Text>
						<Textarea marginBottom="2" placeholder="Optional" /> */}
						{/* <Text marginBottom="2">Thanks for your feedback!</Text> */}
					</Box>}
				</ModalBody>
				<ModalFooter>
					<Button variant="ghost" onClick={!showSurvey ? onClose : () => setShowSurvey(false)}>{!showSurvey ? 'Close' : 'Go Back'}</Button>
					<Button colorScheme="purple" ml={3} isDisabled={!showSurvey && text.length === 0} onClick={() => {onClose(); if (!showSurvey) {submitThoughts()} else {submitSurvey()}}}>{!showSurvey ? 'Share' : 'Submit'}</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ThoughtsModal