import { useState, useRef, useContext, useEffect } from 'react'

import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react'
import { Button, Input } from "@chakra-ui/react"



import {store} from '../../utils/client/store'

import { useRouter } from 'next/router'

import axios from 'axios'

const RenameModal = ({isOpen, onClose}) => {
	const cancelRef = useRef()
	const { state, dispatch } = useContext(store)
	const [title, setTitle] = useState('')
	const router = useRouter()

	useEffect(() => {
		if (state.funnels) {
			const {funnelId} = router.query
			const funnel = state.funnels.find(f => f.id == funnelId)
			setTitle(funnel?.title ?? '')
		} else {
			setTitle('')
		}
	}, [isOpen])

	const renameFunnel = async () => {
		const {funnelId} = router.query
		dispatch({ type: 'SET_FUNNELS', value: state.funnels.map(f => (f.id === funnelId) ? {...f, title} : f) })
		await axios.put(`/api/funnels/${funnelId}`, {title}, { headers: { 'Content-Type': 'application/json', authorization: state.token } })
	}
	return (
		<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						Rename Funnel
									</AlertDialogHeader>
					<AlertDialogBody>
						<Input placeholder="Enter a new name" value={title} onChange={(e) => setTitle(e.target.value)}/>
								</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Cancel
										</Button>
						<Button colorScheme="purple" onClick={() => {renameFunnel(); onClose()}} ml={3}>
							Rename
										</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}
export default RenameModal